// Fonts
//@import "../css/font.css";

// Variables
@import 'web_variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~remixicon/fonts/remixicon.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~notiflix/dist/notiflix-2.6.0.min.css";